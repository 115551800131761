<template>
  <!-- 集体报工 -->
  <div>
    <div class="groupJobBooking_main">
      <van-nav-bar left-text="集体报工"
                   left-arrow
                   @click-left="onClickLeft"
                   :fixed="true">
      </van-nav-bar>
      <div class="content">
        <!-- 今日报工 -->
        <div v-if="isToday"
             class="today_content">
          <div class="search_box">
            <div class="date"
                 @click="checkExDate('today')">
              <i class="fa fa-calendar"></i>
              {{ bd }}
              <span style="margin:0 5px;">至</span>
              {{ ed }}
              <div class="faRight">
                <i class="fa fa-sort-desc"></i>
              </div>
            </div>
            <van-search v-model="searchStr"
                        shape="round"
                        placeholder="单号/订单号"
                        @search="onSearch()" />
          </div>

          <div class="work_list">
            <div class="work_box"
                 v-for="(to,idx) in todayList"
                 :key="idx+'to'">
              <div class="a_row">
                <div class="text_left">
                  <!-- <img :src="require( '@/assets/img/job_booking12.png' )"
                       alt /> -->
                  单号
                </div>
                <div class="text_right"
                     style="font-size:14px">{{to.listno}}</div>
              </div>
              <div class="a_row">
                <div class="text_left">
                  <!-- <img :src="require( '@/assets/img/job_booking12.png' )"
                       alt /> -->
                  订单
                </div>
                <div class="text_right"
                     style="font-size:14px">{{to.orderid}}</div>
              </div>
              <div class="a_row">
                <div class="text_left">
                  <!-- <img :src="require( '@/assets/img/job_booking12.png' )"
                       alt /> -->
                  日期
                </div>
                <div class="text_right">{{to.yymmdd}}</div>
              </div>
              <div class="a_row">
                <div class="text_left">工件编号</div>
                <div class="text_right">{{to.partcode}}</div>
              </div>
              <div class="a_row">
                <div class="text_left">工件名称</div>
                <div class="text_right">{{to.partname}}</div>
              </div>
              <div class="a_row">
                <div class="text_left">
                  <!-- <img :src="require( '@/assets/img/job_booking12.png' )"
                       alt /> -->
                  产线
                </div>
                <div class="text_right">{{to.linename}}</div>
              </div>
              <div class="a_row">
                <div class="text_left">
                  <!-- <img :src="require( '@/assets/img/job_booking13.png' )"
                       alt /> -->
                  工件
                </div>
                <div class="text_right">{{to.partname}}</div>
              </div>
              <div class="a_row">
                <div class="text_left">
                  <!-- <img :src="require( '@/assets/img/job_booking13.png' )"
                       alt /> -->
                  派工数量
                </div>
                <div class="text_right">{{to.pgnum}}</div>
              </div>
              <van-divider />
              <div class="baogong">
                <van-field v-model="to.bgnum"
                           clearable
                           label="报工数量："
                           left-icon="edit"
                           placeholder="报工数量" />
                <van-button type="info"
                            size="small"
                            @click="subimtToday(to)">提交</van-button>
              </div>
            </div>
            <van-empty v-if="todayList.length==0"
                       image="search"
                       description="暂无数据" />
          </div>
        </div>
        <!-- 历史报工 -->
        <div v-else
             class="his_content">
          <div class="search_box">
            <div class="date"
                 @click="checkExDate('today')">
              <i class="fa fa-calendar"></i>
              {{ bd }}
              <span style="margin:0 5px;">至</span>
              {{ ed }}
              <div class="faRight">
                <i class="fa fa-sort-desc"></i>
              </div>
            </div>
            <van-search v-model="searchStr"
                        shape="round"
                        placeholder="单号/订单号"
                        @search="onSearch()" />
          </div>
          <div class="work_list">
            <div class="work_box"
                 v-for="(item,idx) in hisEmpBgList"
                 :key="'his'+idx">
              <img class="examined_img"
                   :src="require(item.ischeck?'@/assets/img/job_booking08.png':'@/assets/img/job_booking07.png')"
                   alt />
              <div class="examined">{{`${item.ischeck?'已审核':'待审核'}`}}</div>
              <div class="a_row"
                   style="margin-top: 15px;">
                <div class="text_left">
                  <!-- <img :src="require( '@/assets/img/job_booking12.png' )"
                       alt /> -->
                  单号
                </div>
                <div class="text_right"
                     style="font-size:14px">{{item.listno}}</div>
              </div>
              <div class="a_row">
                <div class="text_left">
                  <!-- <img :src="require( '@/assets/img/job_booking12.png' )"
                       alt /> -->
                  订单
                </div>
                <div class="text_right"
                     style="font-size:14px">{{item.orderid}}</div>
              </div>
              <div class="a_row">
                <div class="text_left">
                  <!-- <img :src="require( '@/assets/img/job_booking12.png' )"
                       alt /> -->
                  日期
                </div>
                <div class="text_right">{{item.yymmdd}}</div>
              </div>
              <div class="a_row">
                <div class="text_left">工件编号</div>
                <div class="text_right">{{item.partcode}}</div>
              </div>
              <div class="a_row">
                <div class="text_left">工件名称</div>
                <div class="text_right">{{item.partname}}</div>
              </div>
              <div class="a_row">
                <div class="text_left">
                  <!-- <img :src="require( '@/assets/img/job_booking12.png' )"
                       alt /> -->
                  产线
                </div>
                <div class="text_right">{{item.linename}}</div>
              </div>
              <div class="a_row">
                <div class="text_left">
                  <!-- <img :src="require( '@/assets/img/job_booking13.png' )"
                       alt /> -->
                  工件
                </div>
                <div class="text_right">{{item.partname}}</div>
              </div>
              <div class="a_row">
                <div class="text_left">
                  <!-- <img :src="require( '@/assets/img/job_booking13.png' )"
                       alt /> -->
                  派工数量
                </div>
                <div class="text_right">{{item.pgnum}}</div>
              </div>
              <van-divider />
              <div class="baogong">
                <van-field v-model="item.bgnum"
                           clearable
                           label="已报工数量："
                           readonly />
                <van-button v-if="!item.ischeck"
                            type="info"
                            size="small"
                            @click="updateHisNum(item)">修改报工数量</van-button>
              </div>
            </div>
            <van-empty v-if="hisEmpBgList.length==0"
                       image="search"
                       description="暂无数据" />
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="f_btn"
             @click="checkFoot('to')">
          <img :src="require(isToday?'@/assets/img/job_booking03.png':'@/assets/img/job_booking04.png')"
               alt />
          <div>当日报工</div>
        </div>
        <div class="f_btn"
             @click="checkFoot()">
          <img :src="require(isToday?'@/assets/img/job_booking06.png':'@/assets/img/job_booking05.png')"
               alt />
          <div>报工历史</div>
        </div>
      </div>
    </div>
    <!-- 日期日历弹窗 -->
    <van-calendar v-model="showCalendar"
                  :type="whichDate == 'today'?'range':'single'"
                  :allow-same-day="whichDate == 'today'?true:false "
                  :min-date="minDate"
                  :max-date="maxDate"
                  @confirm="onConfirmCal" />

    <!-- 修改报工数量弹窗 -->
    <van-dialog v-model="showUpdateNum"
                class="Automatic_report"
                show-cancel-button
                :confirm-button-text="$t('module.submit')"
                confirm-button-color='#0782F5'
                :beforeClose="updateHisReport">
      <template #title>
        <div class="title">修改报工数量</div>
        <div class="desc">未审核前，可修改报工数量</div>
      </template>
      <div class="content">
        <van-form label-align="right">
          <van-field readonly
                     name="calendar"
                     v-model="updateForm.yymmdd"
                     label="日期" />
          <van-field readonly
                     label="产线"
                     v-model="updateForm.linename" />
          <van-field readonly
                     label="工件"
                     v-model="updateForm.partname" />
          <van-field v-model="updateForm.bgnum"
                     label="报工数量"
                     placeholder="报工数量" />
        </van-form>
      </div>
    </van-dialog>

    <!-- 提示信息弹窗 -->
    <van-dialog v-model="showHintDia"
                class="Automatic_report hint_dialog"
                :show-confirm-button="false">
      <template #title>
        <div style="height:60px"></div>
      </template>
      <div class="content">
        <img :src="require(isSuccess?'@/assets/img/job_booking11.png':'@/assets/img/job_booking10.png')"
             alt />
        <div class="hint_msg">{{hintMessage}}</div>
        <van-button type="info"
                    size="small"
                    round
                    @click="showHintDia = false">好的</van-button>
      </div>
    </van-dialog>

  </div>
</template>

<script>
import { getCurDayGroupBgList, postGroupBg, getHisGroupBgList, saveHisGroupBg } from '@api/wxjj.js'
export default {
  data () {
    const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
    return {
      userInfo,
      isToday: true,
      minDate: new Date(new Date().getFullYear() - 20, 0, 1),
      maxDate: new Date(new Date().getFullYear() + 20, 0, 31),
      today: null,
      bd: this.formatDate(new Date(), 'yyyy-MM-dd'),
      ed: this.formatDate(new Date(), 'yyyy-MM-dd'),
      todayList: [],
      initTodayList: [],
      showCalendar: false,
      whichDate: 'today', // 日历选的是哪个日期，默认选左上角条件日期 
      showUpdateNum: false,
      hisEmpBgList: [], // 历史报工数据
      initHisEmpBgList: [],
      updateForm: {
        yymmdd: '',
        linename: '',
        partname: '',
        bgnum: '',
        autoid: 0
      },
      showHintDia: false,
      isSuccess: true,
      hintMessage: '',
      searchStr: ''

    }
  },
  created () {
    if (this.isToday)    {
      this.getCurDayGroupBgListInfo()
    } else    {
      this.getHisGroupBgListData()
    }
  },
  methods: {
    // 回退
    onClickLeft () {
      this.$router.push('/home')
    },
    // 搜索方法
    onSearch () {
      if (this.searchStr)      {
        if (this.isToday)        {
          this.todayList = this.initTodayList.filter(e => {
            const listno = e.listno ? e.listno : ''
            const orderid = e.orderid ? e.orderid : ''
            return (
              listno.indexOf(this.searchStr) > -1 ||
              orderid.indexOf(this.searchStr) > -1
            )
          })
        } else        {
          this.hisEmpBgList = this.initHisEmpBgList.filter(e => {
            const listno = e.listno ? e.listno : ''
            const orderid = e.orderid ? e.orderid : ''
            return (
              listno.indexOf(this.searchStr) > -1 ||
              orderid.indexOf(this.searchStr) > -1
            )
          })
        }
      } else      {
        if (this.isToday)        {
          this.getCurDayGroupBgListInfo()
        } else        {
          this.getHisGroupBgListData()
        }
      }
    },
    // 提交报工数量
    subimtToday (to) {
      if (to.bgnum)      {
        postGroupBg({
          autoid: to.autoid,
          bgnum: to.bgnum,
          username: this.userInfo.username
        }).then(res => {
          this.showHintDia = true
          if (res.data[0].info == '')          {
            this.isSuccess = true
            this.hintMessage = '报工数量修改成功'
            this.getCurDayGroupBgListInfo()
          } else          {
            this.isSuccess = false
            this.hintMessage = res.data[0].info
          }
        })
      } else      {
        this.showHintDia = true
        this.isSuccess = false
        this.hintMessage = '报工数量不能为空！'
      }
    },
    // 调起日历弹窗方法
    checkExDate (val) {
      this.showCalendar = true;
      this.whichDate = val
    },
    // 日期选择回调
    onConfirmCal (date) {
      if (this.whichDate = 'today')      {
        // this.today = this.formatDate(date, 'yyyy-MM-dd')
        this.bd = this.formatDate(date[0], 'yyyy-MM-dd')
        this.ed = this.formatDate(date[1], 'yyyy-MM-dd')
        if (this.isToday)        {
          this.getCurDayGroupBgListInfo()
        } else        {
          this.getHisGroupBgListData()
        }
      } else      {

      }
      this.showCalendar = false;
    },
    // 获取当日报工数据
    getCurDayGroupBgListInfo () {
      getCurDayGroupBgList({
        username: this.userInfo.username,
        bd: this.bd,
        ed: this.ed
      }).then(res => {
        let result = JSON.parse(JSON.stringify(res.data))
        this.todayList = result
        this.initTodayList = result
      })
    },
    // 获取历史报工数据
    getHisGroupBgListData () {
      getHisGroupBgList({
        username: this.userInfo.username,
        bd: this.bd,
        ed: this.ed
      }).then(res => {
        let result = JSON.parse(JSON.stringify(res.data))
        this.hisEmpBgList = result
        this.initHisEmpBgList = result
      })
    },
    // 修改历史报工数量按钮
    updateHisNum (it) {
      this.showUpdateNum = true
      this.updateForm = { ...this.updateForm, ...it }
    },
    // 修改历史数据弹窗回调
    updateHisReport (action, done) {
      if (action == 'confirm')      {
        if (this.updateForm.bgnum)        {
          saveHisGroupBg({
            autoid: this.updateForm.autoid,
            bgnum: this.updateForm.bgnum,
            username: this.userInfo.username
          }).then(res => {
            this.showHintDia = true
            if (res.data[0].info == '')            {
              this.isSuccess = true
              this.hintMessage = '修改报工提交成功！'
              // 刷新数据
              this.getHisGroupBgListData()
              done();
            } else            {
              this.isSuccess = false
              this.hintMessage = res.data[0].info
              done(false)
            }
          })
        } else        {
          this.showHintDia = true
          this.isSuccess = false
          this.hintMessage = '报工数量不能为空！'
          done(false)
        }
      } else      {
        done();
      }
    },
    // 底部按钮切换
    checkFoot (val) {
      if (val == 'to')      { // 今日
        this.isToday = true
        this.getCurDayGroupBgListInfo()
      } else      { // 历史
        this.getHisGroupBgListData()
        this.isToday = false
      }
    },
    // 不够就补零
    add0 (m) {
      return m < 10 ? '0' + m : m
    },
    // 日期时间转换格式
    formatDate (date, rule) {
      if (date)      {
        const time = new Date(date)
        const y = time.getFullYear()
        const m = time.getMonth() + 1
        const d = time.getDate()
        const h = time.getHours()
        const mm = time.getMinutes()
        const s = time.getSeconds()
        if (rule == 'yyyy-MM-dd')        {
          return y + '-' + this.add0(m) + '-' + this.add0(d)
        } else        {
          return (
            y +
            '-' +
            this.add0(m) +
            '-' +
            this.add0(d) +
            ' ' +
            this.add0(h) +
            ':' +
            this.add0(mm)
          )
          // + ':' + this.add0(s)
        }
      } else      {
        return ''
      }
    },

  }
}
</script>

<style scoped lang="less">
.hint_dialog {
  .content {
    text-align: center;
    img {
      width: 160px;
      height: 160px;
    }
    .hint_msg {
      margin-top: 20px;
      font-size: 32px;
      font-weight: bold;
    }
    .van-button {
      margin: 40px 0;
      width: 240px;
      font-size: 28px;
    }
  }
}
.Automatic_report {
  /deep/.van-dialog__header {
    padding: 20px 0;
    background-image: url("~@/assets/img/job_booking09.png");
    background-repeat: no-repeat;
    background-size: 100%;
    color: #08306b;
    .title {
      font-size: 40px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    .desc {
      font-size: 28px;
    }
  }
}
.groupJobBooking_main {
  height: 100vh;
  background-image: url("~@/assets/img/job_booking01.png");
  overflow: hidden;
  background-color: #eaedef;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-y: 1.22667rem;
  .van-nav-bar {
    height: 92px;
    background: #bcdfff;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #333;
        font-size: 0.5rem;
      }
      .van-nav-bar__text {
        color: #333;
        font-size: 36px;
        font-weight: bold;
      }
    }
    /deep/.van-nav-bar__title {
      color: #333;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    /deep/.van-nav-bar__right {
      .mult_switch {
        display: flex;
        align-items: center;
        .text {
          margin-left: 5px;
          color: #ffffff;
        }
      }
    }
  }
  .van-hairline--bottom::after {
    border: unset;
  }
  .content {
    height: calc(100% - 212px);
    margin-top: 92px;
    overflow-y: auto;
    .today_content,
    .his_content {
      .search_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .van-search {
          background-color: unset;
          padding-top: 0;
          padding-bottom: 0;
          max-width: 45%;
        }
      }
      .date {
        font-size: 24px;
        padding: 20px;
        display: flex;
        align-items: center;
        .fa-calendar {
          margin-right: 6px;
        }
        .faRight {
          i {
            margin-left: 6px;
            margin-bottom: 16px;
          }
        }
      }
      .work_list {
        .work_box {
          font-size: 28px;
          background: #fff;
          box-shadow: 8px 6px 30px 0px rgba(175, 175, 175, 0.21);
          border-radius: 10px;
          margin: 20px;
          padding: 20px;
          border-left: 8px solid #93a6bd;
          .text_left {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            color: #ababab;
            width: 25%;
            padding-right: 20px;
            img {
              width: 28px;
              height: 28px;
              margin-right: 10px;
            }
          }
          .text_right {
          }
          .a_row {
            display: flex;
            margin-bottom: 20px;
          }
          .b_row {
            display: flex;
            .c_row {
              flex: 1;
              display: flex;
              .text_left {
                width: 50%;
                text-align: right;
                padding-right: 20px;
              }
            }
          }
          .van-divider {
            margin: 20px 0;
          }
          .baogong {
            display: flex;
            align-items: center;
            /deep/.van-field {
              padding: 0;
              flex: 1;
              .van-field__left-icon {
                color: #007bee;
              }
              .van-field__label {
                color: #007bee;
              }
            }
            .van-cell::after {
              border-bottom: 0;
            }
            .van-button {
              width: 150px;
            }
          }
        }
      }
    }
    .his_content {
      .work_list {
        .work_box {
          position: relative;
          border-left: 0;
          .examined_img {
            position: absolute;
            top: 0px;
            left: 0;
            width: 150px;
          }
          .examined {
            position: absolute;
            left: 30px;
            top: 0px;
          }
          .baogong {
            /deep/.van-field {
              .van-field__label {
                margin-right: 0;
              }
            }
            .van-button {
              width: 200px;
            }
          }
        }
      }
    }
  }
  .footer {
    height: 120px;
    background: #fff;
    width: 100%;
    position: absolute;
    bottom: 0;
    font-size: 28px;
    display: flex;
    .f_btn {
      flex: 1;
      text-align: center;
      padding-top: 10px;
      img {
        width: 60px;
        height: 60px;
      }
    }
  }
}
</style>